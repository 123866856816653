import * as S from "./style";
import React, { useEffect, useState } from "react";
import { useCampaign } from "../../context/CampaignContext";
import { AxiosRequestNextGame } from "../../domain/AxiosRequestNextGame";

interface INextMatch {
  name: string;
  logo: string;
  league: { name: string };
  date: string;
}

function NextMatch(): React.ReactElement {
  const [nextMatch, setNextMatch] = useState<INextMatch>();
  const { campaignData } = useCampaign();

  useEffect(() => {
    async function findNextMatch() {
      const request = new AxiosRequestNextGame()
      const nextMatchObj = await request.get()
      // Atualiza o estado com os dados do próximo adversário
      setNextMatch({
        ...nextMatchObj
      });
    };

    !nextMatch && findNextMatch().catch((e) => console.log(e));
  }, [nextMatch]);
  const dayMonth = (new Date(nextMatch?.date || "")).toLocaleString("pt-BR", { month: "2-digit", day: "2-digit" })
  const hour = (new Date(nextMatch?.date || "")).toLocaleString("pt-BR", { hour: "2-digit", minute: "2-digit" })

  return (
    <S.NextMatchContainer>
      <S.NextMatchTitle>
        <span>
          {nextMatch?.date && dayMonth}
        </span>
        <span>
          Próximo jogo
        </span>
        <span>
          {nextMatch?.date && `${hour}h`}
        </span>
      </S.NextMatchTitle>
      <S.Players>
        <S.NextMatchPlayer>
          <img src={campaignData.teamShield} alt="escudo-casa" className="home" />
          <span>{campaignData.teamName}</span>
        </S.NextMatchPlayer>
        <span>X</span>
        {nextMatch ? (
          <>
            <S.NextMatchPlayer>
              <img src={nextMatch.logo} alt="escudo-opposing-team" />
              <span>{nextMatch.name}</span>
            </S.NextMatchPlayer>
          </>
        ) : (
          <>
          <S.NextMatchPlayer>
            <img src="favicon.ico" alt="escudo-opposing-team" />
            <span>Em breve</span>
          </S.NextMatchPlayer>
        </>
        )}
      </S.Players>
    </S.NextMatchContainer>
  );
}

export default NextMatch;
